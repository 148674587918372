import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  WrapperLight,
  WrapperDark,
  Content,
} from "../components/section-styling"
import styled from "styled-components"
import Accordion from "../components/Accordion"
import Layout from "../components/layout"
import Seo from "../components/seo"

const OurWorkContent = styled(Content)`
  flex-flow: row wrap;
`

/*
  Known issue: a console warning is appearing when navigating to this page:
  "Warning: Each child in a list should have a unique "key" prop."
*/

const OurWorkPage = () => (
  <Layout>
    <Seo title="Our Work" />

    <WrapperDark>
      <Content>
        <div>
          <h1>Building together.</h1>
          <p>
            We care for and improve long-standing tools that organizers, NGOs,
            artists, researchers, and other change-makers rely on.
          </p>
        </div>

        <StaticImage
          src="../images/sassy-plant-25.png"
          height={500}
          alt=""
          width={525}
        />
      </Content>
    </WrapperDark>

    <WrapperLight>
      <OurWorkContent>
        <h3>Some examples of our work:</h3>
        <Accordion
          title="Madeline for Seed Commons"
          text={[
            "Madeline is a loan administration tool for ",
            <a
              href="https://seedcommons.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Seed Commons
            </a>,
            ", a national network of locally-rooted, non-extractive loan funds that brings the power of big finance under community control. They support local cooperative businesses in order to build the infrastructure necessary for a truly just, democratic and sustainable new economy.",
          ]}
        />
        <Accordion
          title="TEXERE: The Shape of Loss as a Tapestry"
          text={[
            <a
              href="https://texere.space/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TEXERE
            </a>,
            " is a hybrid online and physical exhibition built in collaboration with artist ",
            <a
              href="https://www.indiraallegra.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Indira Allegra
            </a>,
            ". The project is a monument to collective grief, weaving personal narratives of loss into online memorials. The physical exhibition invites people to contribute visual, audio, and textual offerings to digital tapestries. These tapestries are projected in the space and updated in real time, creating a living testimony to our shared losses.",
          ]}
        />
        <Accordion
          title="Shora"
          text={[
            "Shora is an experiment in creating an alternative social media network, pushing back against norms of abuse and harassment to instead build community tools with safety, consent, and care. This work is currently funded by the ",
            <a
              href="https://www.sbir.gov/sbirsearch/detail/2081769"
              target="_blank"
              rel="noopener noreferrer"
            >
              National Science Foundation
            </a>,
            " and is being envisioned and designed in collaboration with the ",
            <a
              href="https://amc.alliedmedia.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allied Media Conference
            </a>,
            " and ",
            <a
              href="https://www.andalsotoo.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              And Also Too
            </a>,
            ".",
          ]}
        />
        <Accordion
          title="NEMO for the Carter Center"
          text={[
            <a href="https://getnemo.org/">NEMO</a>,
            " is the Carter Center's open-source data collection and reporting system. Users can submit data via Android devices, SMS, or from a web browser in real time. Researchers then review the data and draw inferences to suggest improvements to local infrastructure and community health. The whole system works entirely offline. The Carter Center uses NEMO for their data-driven research in various countries and has collected hundreds of millions of data points over the past decade.",
          ]}
        />

        <Accordion
          title="Monument Quilt for Force"
          text={[
            "The ",
            <a
              href="https://app.themonumentquilt.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Monument Quilt
            </a>,
            " is a physical collection of thousands of stories from survivors of rape and abuse, serving as a monument and healing space for survivors. We supported FORCE in building a mobile-friendly web app to document each square that makes up the quilt. It was used as their live event app during the time the quilt was displayed on the National Mall in Washington, D.C. in May and June 2019, and now lives on as a searchable record of each of the quilt squares. To meet the needs of both a live event and an archive on a very limited budget, we used the latest progressive web app tech for a responsive user experience, and Jekyll to produce static HTML that will last many years without expensive maintenance.",
          ]}
        />

        <Accordion
          title="Heartmob for Right to Be"
          text={[
            "Right to Be is a non-profit working to end harassment in public spaces, especially harassment directed towards women and queer people. In partnership with Right to Be, we created Heartmob, a platform for educating and supporting targets of online harassment. Our ongoing work on this project has included research, graphic design, interaction design, and development. Heartmob has been featured in the New York Times, won Netroots' Best New Product, and has ",
            <a
              href="https://dl.acm.org/doi/10.1145/3411764.3445778"
              target="_blank"
              rel="noopener noreferrer"
            >
              produced award winning academic publications
            </a>,
            ".",
          ]}
        />
        <Accordion
          title="T2 Coach for Columbia University"
          text="T2 Coach is a suite of tools for Type 2 diabetes research. Users receive near-real-time feedback on their meals' nutritional content. Studies have shown this feedback improves nutritional estimation ability, which can be important for healthy eating."
        />
        <Accordion
          title="RealRisks for Columbia University"
          text="RealRisks is a web app to help patients understand their breast cancer risk. It supports breast cancer prevention by providing education, risk models, recommendations, and info patients can bring to discuss with their health care provider."
        />
      </OurWorkContent>
    </WrapperLight>
  </Layout>
)

export default OurWorkPage
